import { AppConstants as constants } from 'app-constants';
import { ActivatedRoute, Router } from '@angular/router';
import { ModuleSelectorDropdownComponent } from './../module-selector-dropdown/module-selector-dropdown.component';
import { ServerService } from 'app/server.service';
import { Component, OnInit } from '@angular/core';
import { NavbarService } from 'app/navbar.service';
import { take, switchMap, map } from 'rxjs/operators';
import { Grading } from '../../data-model/grading';
import { GradingSessionService } from 'app/grading-session.service';
import { Grade } from 'data-model/grade';
import { LearnableSelectionService } from 'app/learnable-selection.service';
import { LearnableSelector } from 'app/learnable-selector';
import { GradingSession } from 'data-model/gradingSession';
import { Gradeable } from 'data-model/gradeable';
import { Subject, Subscription, timer } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Title } from '@angular/platform-browser';

class Student {
    id: String;
    name: String
}

@Component({
    selector: 'app-grading-menu',
    templateUrl: './grading-menu.component.html',
    styleUrls: ['./grading-menu.component.scss'],
    providers: [
        GradingSessionService,
        LearnableSelectionService
    ]
})
export class GradingMenuComponent implements OnInit {

    debugObservables: boolean = false;
    subscriptions: Array<any>
    states;
    gradingList: Array<Grading> = []
    currentGrading: Grading = null;
    currentGradingSession: GradingSession = null;
    gradeableDisplayList: Gradeable[][]
    newGrading: Grading = null;
    studentList: Student[] = null;
    studentToGrade: Student;
    studentNameOverride: String;
    saving: boolean;
    needsSave: boolean;
    saveTimerSubject: Subject<any>;
    description: String = "";
    learnablesSelected: number;
    moduleSelected: String = null;
    notesExpanded: boolean = false;
    showComplete: boolean = false;
    hover: boolean[] = [];
    deleteGradingConfirmName = '';
    deleteGradingIndex: number;
    gradingSessionSubscription: any;
    grader: String;
    isAdmin: boolean = false;
    
    private _showActiveOnly: boolean = true;
    
    set showActiveOnly(value) {
        this._showActiveOnly = value;
        this.studentToGrade = null;
        this.studentNameOverride = null;
        this.studentList = null;
        this.getUserList(value);

    }
    
    get showActiveOnly(){
        return this._showActiveOnly
    }
    



    constructor(
        private navbarService: NavbarService,
        private serverService: ServerService,
        private gradingSessionService: GradingSessionService,
        private learnableSelectionService: LearnableSelectionService,
        private modalService: NgbModal,
        private route: ActivatedRoute,
        private router: Router,
        private titleService: Title 
    ) { }

    serverListSubscription: Subscription;

    getUserList(showActiveOnly){
        if (this.serverListSubscription) {
            this.serverListSubscription.unsubscribe();
        }

        this.serverListSubscription = this.serverService.getUserList(showActiveOnly)
        .pipe(
            map(list => list.sort((a,b) => {
                return (a.name && b.name) ? (a.name.localeCompare(b.name)) : (
                    (a.email && b.email) ? a.email.localeCompare(b.email) : 0 
                )
            }))
        )
        .subscribe(list => {
            this.studentList = list;
        })
    }

    ngOnInit() {
        this.learnableSelectionService.selectAllCategories();        
        
        this.subscriptions = [];
        //update the navbar to indicate the curent state is "learn"
        this.subscriptions.push(this.navbarService.getNavbarStates()
        //do this only once then close the stream of observables
        .pipe(take(1))
        .subscribe(
			      newStates => {
                this.states = newStates;
                Object.keys(this.states).forEach(state => this.states[state] = false);
                this.states.grade = true;        
            }
        )); 

        this.grader = this.serverService.getUserId();
        this.isAdmin = this.serverService.isUserAdmin()

        this.serverService.getGradingListSubjectForInstructor().subscribe(list =>{
            this.gradingList = list;
        // Extract a fragment from the URL if it exists
            this.subscriptions.push(
                this.route.fragment
                .pipe(map(fragment => fragment || undefined))
                .subscribe(fragment => {
                    if (this.debugObservables) console.log("** Observed Route Fragment **", fragment)
                    if (fragment) {
                        var regexResult = /^gid=(.*)/.exec(fragment)
                        //console.log("Fragment Regex Result",regexResult);
    
                        if (regexResult && regexResult.length > 1){
                            var gradingId = regexResult[1] || undefined;
                            if (this.debugObservables) console.log("Fragment grading ID: " + gradingId);
                            if (gradingId){
                                this.selectGradingById(gradingId);
                                //select the grading and start a grading session
//                                this.learnableSelectionService.selectLearnable(gradingId);
                                //console.log("Selecting Learnable from hash: " + learnableId);
                            }
                
                        } //else {
                          //  this.learnableDetailsActive = false;
                        //}
                    } //else {
                      //  this.learnableDetailsActive = false;
                    //}
                })
            )
        })

        //get the updated list - need to call this every time we cahnge the list like adding a new one or deleting one)
        this.serverService.updateGradingListForInstructor();


        this.getUserList(this.showActiveOnly)
        // //todo: initialize "studentToGrade" with student list for the curriculum.
        // this.studentList = [
        //     {
        //         id: "1234567890",
        //         name: "James van Kessel"
        //     },
        //     {
        //         id: "9876543210",
        //         name: "Michael Ginn"
        //     }
        // ]

        this.studentToGrade = null;
        this.studentNameOverride = null;

        if (this.gradingSessionSubscription == null) this.gradingSessionSubscription = this.gradingSessionService.getGradingSessionObservable()
        .subscribe((gs: GradingSession) => {
            this.currentGradingSession = gs;
            if (gs && gs.gradeables){
                if ( gs.grading ) this.setTitleForGrading(gs.grading);
                this.gradeableDisplayList = gs.getDisplayList();
            } else {
                this.setGradingTitle();
            }
        })

        this.gradingSessionService.getSavingObservable()
        .subscribe(saving => {
            this.saving = saving;
        })

        // Create a timer that accumulates events and once a timer completes, it will save.
        this.saveTimerSubject = new Subject();

        this.saveTimerSubject
        .pipe(
            switchMap(() => timer(3000)) //wait until 2 seconds after the last event before continuing
        )
        .subscribe(() => this.saveGrading()) // after waiting, save.

        this.learnableSelectionService.getLearnableSelectionList().subscribe(lsList => {
            this.learnablesSelected = lsList.length;
        })

    }

    setGradingTitle(){
        this.titleService.setTitle("Grading | " + constants.appTitle);
    }

    onModuleSelected(modName: String){
        //console.log("module selection state change to: " + state)
        this.moduleSelected = modName;
        this.description = modName;
    }

    createAndSaveNewGrading(){
        // Set description to somehting!
        this.createNewGrading();
        this.saveNewGrading();
    }

    createNewGrading(){
        this.newGrading = 
            new Grading(
                    this.description, 
                    new Date(), 
                    constants.curriculum, 
                    this.serverService.getUserId(), 
                    this.studentToGrade.id, 
                    this.studentNameOverride || this.studentToGrade.name);

        // 

        // temporary code to add learnables to the grading.  Replace with dialog for selecting content 
        // this.newGrading.grades.push({learnableId: "58ab4b209e0bff263422002b", results: new Grade()})
        // this.newGrading.grades.push({learnableId: "58ab4b269e0bff2634220095", results: new Grade()})
        // this.newGrading.grades.push({learnableId: "58ab4b249e0bff2634220068", results: new Grade()})
        // this.newGrading.grades.push({learnableId: "58ab4b229e0bff263422004a", results: new Grade()})
        // this.newGrading.grades.push({learnableId: "58ab4b2e9e0bff26342200b8", results: new Grade()})
        // this.newGrading.grades.push({learnableId: "58ab49909e0bff263421fce1", results: new Grade()})
        // this.newGrading.grades.push({learnableId: "58ab49909e0bff263421fd54", results: new Grade()})

  

        // TODO: Redirect to grading view

        //this should not happen until the end when the grading is fully ready (including learnables selected)

        
        

    }

    saveNewGrading(){
        // gather learnables list from LEarnableSelectionService and add them to the gradingList
        this.learnableSelectionService.getLearnableSelectionList()
        .pipe(
            take(1)
        )
        .subscribe((learnableSelections: LearnableSelector[]) => {

            learnableSelections.forEach((ls: LearnableSelector) => {
                this.newGrading.grades.push({learnableId: ls.id, results: new Grade()})
            })


            this.gradingList.push(this.newGrading); //remove when saving list and updating list subject
            this.currentGrading = this.newGrading; 
            
            // reset new grading form
            this.studentToGrade = null;
            this.studentNameOverride = null;
            this.newGrading = null;
            this.description = "";
            this.moduleSelected = null;

            // start grading session
            this.gradingSessionService.init(this.currentGrading);
            //console.log("** GradingMenuComponent.saveNewGrading() new grading:",this.currentGrading)
            this.saveGrading(); //forces update of grading list automatically and updates currentGrading
        });

    }

    setTitleForGrading(grading){
        this.titleService.setTitle(grading.studentName + " - " + grading.description  + " - Grading | " + constants.appTitle);
                
    }

    saveGrading(){
        this.needsSave = false;
        this.gradingSessionService.saveGrading()
        .subscribe((grading: Grading) => {
//            this.currentGrading = grading;
            this.navigateToGradingById(grading._id)
        })

    }

    exitGrading(){
        this.gradingSessionService.endSession();
        this.router.navigate(['/grade'])
    }

    printGrading(){
        this.currentGrading.grades.forEach((grade) => {
            console.log(grade.learnableId + ": ", grade.results)
        })
    }

    navigateToGradingById(gId){
        this.router.navigate(['/grade'],{fragment:"gid="+gId, queryParamsHandling: 'preserve'});
    }

    selectGradingById(gId){
        let foundGrading: Grading = this.gradingList.find(grading => grading._id == gId)
        if (foundGrading){
            this.selectGrading(foundGrading);
        }
    }

    selectGrading(grading: Grading){
        this.currentGrading = grading;
        //console.log("** GradingMenuComponent.selectGrading():", grading)
        this.gradingSessionService.init(this.currentGrading);
    }
    // After selecting a grading or creating one, build a gradingSession object which needs to be:
    //  An ordered list of Learnables, ordered by Belt then Category with specific details: 
    //  Module name, Module Icon, Category Name, Learnable Name, and the corresponding Grade object that will
    //  go into the final Grading object we're storing (in the same order)
    //  Build the gradingSessionService, then sort the learnables and then link the Grade objects into the Grading object.

    deleteGrading(index: number){
        this.serverService.deleteGrading(this.gradingList[index]._id)
        .subscribe(() => {});

    }

    startSaveTimer(){
        this.needsSave = true;
        this.saveTimerSubject.next( void 0)
        // console.log("** GradingMenuComponent.startSaveTimer currentGrading:",this.currentGrading.grades[0].results)
        // console.log("** GradingMenuComponent.startSaveTimer gradeableDisplayList:",this.gradeableDisplayList[0][1].grade)

    }

    openDeleteConfirmModal(content,name,index): void{
        this.deleteGradingConfirmName = name;
        this.deleteGradingIndex = index
        this.modalService.open(content, {centered: true});
    }
}
