import { ModSet } from './mod-set';
import { Grade } from './grade';
import { Grading } from 'data-model/grading';
import { Gradeable } from "data-model/gradeable";
import { ReturnStatement } from '@angular/compiler';
import { Learnable } from './learnable';

type DisplayList = Gradeable[][];

export class GradingSession {
    gradeables: Gradeable[];
    grading: Grading;
    constructor(grading: Grading, modSet: ModSet) {
        this.grading = grading;
        this.gradeables = new Array<Gradeable>();
        if (grading) {
            grading.grades.forEach((grade) => {
                //get the learnable that matches the ID and load it into this.gradeables)
                let learnable = modSet.learnables.find(learnable => learnable._id == grade.learnableId);
                if (learnable === undefined) {
                    console.log("Problem processing graded Learnable: cannot find Learnable with id: " + grade.learnableId 
                        + "; Grade Object that caused the issue follows: ");
                    console.log(grade);
                    return;
                }
                let mod = modSet.modules.find(module => module._id == learnable.module);
                if (mod === undefined) {
                    console.log("Problem processing graded Learnable: cannot find Module with id: " + learnable.module
                        + "; Grade Object that caused the issue follows: ");
                    console.log(grade);
                    return;
                }
                let category = modSet.categories.find(category => category._id == learnable.category);
                if (category === undefined) {
                    console.log("Problem processing graded Learnable: cannot find Category with id: " + learnable.category 
                        + "; Grade Object that caused the issue follows: ");
                    console.log(grade);
                    return;
                }
                let gradeable = new Gradeable(grade.results, learnable, category, mod);
                this.gradeables.push(gradeable);
            });
        }
        this.sort(modSet);
    }
    /**
     * Sorts the GradingSession
     *
     * @param modSet the ModSet that includes ordered lists that will be used to sort the list
     */
    sort(modSet: ModSet) {
        this.gradeables.sort((a, b) => {
            //sort order needs to be by: Module then Category, then Learnable in order stored in ModSet)
            let aModIndex = modSet.modules.findIndex(mod => a.learnable.module == mod._id);
            let bModIndex = modSet.modules.findIndex(mod => b.learnable.module == mod._id);
            
            let m = aModIndex - bModIndex;
            if (m == 0) {
                //Modules are the same so sort by category
                let aCatIndex = modSet.categories.findIndex(cat => a.learnable.category == cat._id);
                let bCatIndex = modSet.categories.findIndex(cat => b.learnable.category == cat._id);
                // find sort order handling case hwere category no longer exists
                let aCatSortOrder = aCatIndex < 0 ? -1 : modSet.categories[aCatIndex].sortOrder;
                let bCatSortOrder = bCatIndex < 0 ? -1 : modSet.categories[bCatIndex].sortOrder;
    
                //handle case where category sort is undefined.
                let c = (aCatSortOrder || 0) - (bCatSortOrder || 0);
                if (c == 0) {  
                    // Categories are the same so sort by sort Order.
                    let s = (a.learnable.sortOrder || 0) - (b.learnable.sortOrder || 0)
                    if (s == 0) {
                        //SortOrders are the same so sort by Learnable Level
                        let aLevelIndex = a.module.levels.findIndex(modLevel => a.learnable.level == modLevel);
                        let bLevelIndex = b.module.levels.findIndex(modLevel => b.learnable.level == modLevel);
                        
                        let l = aLevelIndex - bLevelIndex
                        if (l == 0) {
                              return a.learnable.name.localeCompare(b.learnable.name);
                        } 

                        // Handle case where one level is unindexed
                        if (aLevelIndex < 0) {
                          return -1;
                        } else if (bLevelIndex < 0) {
                          return 1;
                        }

                        return l;
                      
                    }
                    return s;
                }        
                
                // Handle case where one category is unindexed
                if (aCatIndex < 0) {
                  return -1;
                } else if (bCatIndex < 0) {
                  return 1;
                }

                return c;
            }
            
            // Handle case where one level is unindexed
            if (aModIndex < 0) {
              return -1;
            } else if (bModIndex < 0) {
              return 1;
            }

            return m;           
        });
        // this.gradeables.forEach((gradeable: Gradeable) => {
        //     console.log("Mod: " + gradeable.learnable.module + "; Cat: " + gradeable.learnable.category + "; Learnable: " + gradeable.learnable.name);
        // });
    }

    getDisplayList(): Array<Array<Gradeable>> {
        let batch: number = -1; // loop will increment this to 0 on first run
        let priorModule = null;
        let gradeableDisplayList = new Array<Array<Gradeable>>(); 
        if (this.gradeables){

            this.gradeables.forEach((gradeable: Gradeable) => {
                if (gradeable.module._id !== priorModule) {
                    batch++
                    gradeableDisplayList.push([]);
                }
                gradeableDisplayList[batch].push(gradeable);
                priorModule = gradeable.module._id;
            })
        } else {
            gradeableDisplayList = [];
        }
        return gradeableDisplayList

    }

}
